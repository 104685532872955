@use '@danfoss/mosaic-sass/dist/entry' as *;
@use './../../styles/variables.scss' as v;
@use '../../styles/mixins' as mixins;

$reset-to-0: 0 !default;
$timing: 0.3s ease-in-out;
$transition: max-width $timing, transform $timing;
$mobile-right-side-width: v.$left-sidebar-max-width;

@mixin sidebar-sliding-animation($rsw: $reset-to-0, $lsw: $reset-to-0) {
    &.right {
        width: 100%;
        max-width: $rsw;
        transition: $transition;

        .scroll {
            overflow-y: scroll;
            @include mixins.thin-scroll;
        }
    }

    &.left {
        width: 100%;
        max-width: $lsw;
        transition: $transition;
    }
}

.sidebar {
    @include match-breakpoint-down(sm) {
        &.left {
            background-color: cssvar(color-primary);
        }

        &.right,
        &.left {
            // For mobile purposes. The chat header will have some items with high z-index, hence 4 is needed.
            z-index: 4;
        }
    }

    // SCENARIO (undefined)
    @include sidebar-sliding-animation();

    &.right {
        display: flex;
        flex-direction: column;

        border-left: 1px solid cssvar(color-neutral-base);
        border-right: 0;

        // SCENARIO (Tablet)
        &:global(._sidebar-open) {
            @include sidebar-sliding-animation(v.$right-sidebar-width, $reset-to-0);
        }

        // SCENARIO (Desktop)
        @include match-breakpoint-up(lg) {
            &:global(._sidebar-open) {
                @include sidebar-sliding-animation(v.$right-sidebar-width, v.$left-sidebar-max-width);
            }
        }

        // SCENARIO (Mobile)
        @include match-breakpoint-down(sm) {
            height: 100%;
            max-width: v.$left-sidebar-max-width;
            transform: translateX(100%);
            position: absolute;
            right: 0;

            &:global(._sidebar-open) {
                @include sidebar-sliding-animation($mobile-right-side-width, $reset-to-0);
                transform: translateX(0);

                & + .backdrop {
                    pointer-events: all;
                    opacity: 1;
                }
            }
        }
    }

    &.left {
        display: flex;
        flex-direction: column;

        border-right: 1px solid cssvar(color-neutral-base);
        border-left: 0;

        // SCENARIO (Tablet)
        &:global(._sidebar-open) {
            @include sidebar-sliding-animation($reset-to-0, v.$left-sidebar-max-width);
        }

        // SCENARIO (Desktop)
        @include match-breakpoint-up(lg) {
            @include sidebar-sliding-animation(v.$right-sidebar-width, v.$left-sidebar-max-width);
        }

        // SCENARIO (mobile)
        @include match-breakpoint-down(sm) {
            height: 100%;
            position: absolute;
            max-width: v.$left-sidebar-max-width;
            transform: translateX(-100%);
            left: 0;

            &:global(._sidebar-open) {
                display: flex;

                @include sidebar-sliding-animation($reset-to-0, v.$left-sidebar-max-width);
                transform: translateX(0);

                & + .backdrop {
                    pointer-events: all;
                    opacity: 1;
                }
            }

            .scroll {
                display: flex;
                flex-direction: column;
                flex: 1;
                // overflow-y: scroll;
                // height: 100vh;
            }
        }
    }

    .scroll {
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        height: 100%;
    }

    hr {
        margin: 0;
    }
}

.content-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    height: 100%;
    width: 100%;
}

.content {
    display: flex;
    position: absolute;
    inset: 0;
    flex-direction: column;

    &:not(.visible) {
        opacity: 0;
    }
}

.backdrop {
    cursor: pointer;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    pointer-events: none;
    transition: opacity $timing;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}