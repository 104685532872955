@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../../styles/mixins.scss' as mixins;

.message {
    display: flex;
    align-items: flex-start;

    .avatar {
        padding-right: get-spacing(3);

        img,
        svg,
        span {
            width: 30px !important;
            height: 30px !important;
            border-radius: 50%;
        }

        svg,
        span {
            background-color: darken(#fff, 2);
            outline: 1px solid;
            border-radius: 50%;
            padding: 3px;
            border-radius: 50%;
        }
    }

    .content {
        flex: 1;
        width: calc(100% - get-spacing(10)); // Reset on mobile ... > Compensate for the padding-right of message ChatWindow.module.scss

        .headline {
            line-height: 1.4;
            padding-bottom: get-spacing(1);
            font-size: 18px;

            display: flex;
            align-items: center;

            .extras {
                margin-left: get-spacing(3);
                font-size: 12px;
                color: cssvar(color-secondary);
            }

            .beta {
                margin-left: 8px;
            }
        }

        .generation-status {
            vertical-align: top;
            opacity: 0.8;
            margin-left: 10px;
        }

        .message-text {
            width: 100%;
            font-size: 16px;
            overflow-wrap: break-word;

            pre,
            code {
                max-width: calc(100% - 50px);
                overflow: auto;
            }
        }
    }

    &:hover {
        .enquiry-analysis {
            opacity: 1;
        }
    }

    .enquiry-analysis {
        margin-left: auto;
        opacity: 0;
        transition: 0.3s ease-in-out;

        @include mixins.mobileDevice {
            opacity: 1;
        }

        > span {
            cursor: pointer;
        }
    }

    .source-container {
        display: flex;
        align-items: center;
        gap: get-spacing(4);

        .chip {
            & + .chip {
                margin-left: get-spacing(2);
            }
        }
    }
}

.snippet {
    & + .snippet {
        margin-top: get-spacing(3);
    }

    .snippet-ctas {
        margin-top: get-spacing(3);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: get-spacing(2);

        @include match-breakpoint-down('sm') {
            & {
                flex-wrap: wrap;

                a,
                button {
                    width: 100%;
                }
            }
        }
    }

    a {
        margin-left: auto;
        text-decoration: none !important;
    }
}



// Mobile headline component
.headline-base {
    display: flex;
    justify-content: baseline;
    align-items: center;
    gap: get-spacing(2);

    .name {
        @include mixins.ellipsis(1);
    }

    .avatar {
        padding-right: 0;
    }
}

.headline-extras {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px
}

.mobile {
    display: block;

    .content {
        width: 100%
    }

    .headline {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        width: 100%;
        row-gap: 4px;
    }

    .extras,
    .beta,
    .enquiry-analysis {
        margin-left: 0 !important;
    }
}