@use '@danfoss/mosaic-sass/dist/entry' as *;

:global(.df-mosaic) .hero {
    position: relative;
    width: 100%;

    .image {
        background-image: url('../../../public/images/hero-default-image.jpg');
        height: 290px;
    }

    h1 {
        margin-bottom: get-spacing(3);
    }
}
