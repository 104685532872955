@use "@danfoss/mosaic-sass/dist/entry" as *;

.actions-container {
    width: 100%;
    display: flex;
    border-bottom: 1px solid cssvar(color-object-weak);
    gap: get-spacing(6);
    margin-bottom: get-spacing(2);
    padding: get-spacing(2);
}

.actions {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: get-spacing(4);
    width: 100%;

    label {
        display: flex;
        align-items: center;
        flex: 0;
        gap: get-spacing(1);

        &:hover {
            cursor: pointer;
            color: cssvar(color-text-strong) !important;
        }
    }

    label._disabled {
        color: cssvar(color-object-weaker) !important;

        &:hover {
            cursor: default;
        }
    }
}

.invalid-message {
    color: cssvar(color-error);
    font-size: 14px;
    line-height: 1.5;
    margin-top: get-spacing(2);
}

.content {
    position: relative;
    width: 100%;
}

.select-container {
    padding-top: get-spacing(4);
}