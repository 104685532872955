@use "@danfoss/mosaic-sass/dist/entry" as *;

// List item
.list-item {
    position: relative;
    display: flex;
    align-self: start;
    margin-bottom: get-spacing(1);
    margin-top: get-spacing(3);
    padding: get-spacing(2);
    gap: get-spacing(3);

    input {
        position: relative;
        margin: 0;
        flex: 0 1;
        outline: none;
    }
}

.loader-overlay {
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: cssvar(color-object-weakest);
    opacity: 0.8;

    margin: get-spacing(-2);
}