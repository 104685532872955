@use "@danfoss/mosaic-sass/dist/entry" as *;

.file-drop-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.df-file-drop {
  align-items: center;
  border: 2px dashed cssvar(color-object-weak);
  display: flex;
  justify-content: center;
  min-height: 136px;

  &--dragging {
    border-color: cssvar(color-secondary) !important;
  }
}
