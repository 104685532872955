@use "@danfoss/mosaic-sass/dist/entry" as *;

.input-container {
    b {
        display: block;
        margin-bottom: get-spacing(1);
    }
}

.document-update-modal :global(.df-modal) {
    p {
        margin-bottom: get-spacing(2);
    }
}

.df-form-field {
    &:not(:first-of-type) {
        margin-top: get-spacing(4) !important;
    }
}

.error {
    input {
        border-color: cssvar(color-error) !important;
    }
}

.error-message {
    color: cssvar(color-error);
    margin-top: get-spacing(1) !important
}