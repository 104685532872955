@use '@danfoss/mosaic-sass/dist/entry' as *;

.document-list {
    margin-top: get-spacing(4);
    margin-bottom: get-spacing(6);

    .header {
        margin-bottom: get-spacing(3)
    }

    .children {
        margin-bottom: get-spacing(2)
    }

    :is(.description-cta) {
        font-size: 14px;
        line-height: 1;
    }
}

.chip-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: get-spacing(3);

    .progress {
        min-width: 80px;
        text-align: center;
        line-height: 1;

        span {
            width: 100%;
        }
    }

    .separator {
        color: cssvar(color-object-weak);
    }
}